//
// _profile.scss
//

.profile-user {
    position: relative;
    display: inline-block;

    .profile-photo-edit {
        position: absolute;
        right: $card-spacer-x;
        left: auto;
        top: $card-spacer-y;
        cursor: pointer;
    }

    .user-profile-image {
        object-fit: cover;
    }

    .profile-img-file-input {
        display: none;
    }
}

.profile-basic{
    height: 180px;   
}

//account

.profile-user-img {
    img {
        height: 285px;
        width: 100%;
    }
}

//activity-timeline
.acitivity-timeline {
    position: relative;
    overflow: hidden;

    .acitivity-item {
        position: relative;

        .flex-shrink-0 {
            z-index: 2;
        }

        .acitivity-avatar {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-top: 8px;
            background-color: $body-secondary-bg;
            border: 1px solid $primary;
            box-shadow: rgba($primary, 0.2) 2px 2px, rgba($primary, 0.1) 4px 4px, rgba($primary, 0.05) 6px 6px;
        }

        &:before {
            content: "";
            position: absolute;
            border-left: 1px dashed var(--#{$prefix}border-color);
            left: 4px;
            height: 100%;
            top: 8px;
            z-index: 0;
        }

        &:last-child {
            &::before {
                border-color: transparent;
            }
        }
    }
}